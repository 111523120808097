<template>
  <models
    :showmodel="showcreate"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmcreate"
    @closemodel="closecreate"
    class="ifcreate"
  >
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
      <div class="maincontent">
        <el-row>
          <el-col :span="6">
            <div class="labelname"><span>发票类型：</span></div>
          </el-col>
          <el-col :span="18">
            <div class="namevalue2">
              <el-radio-group v-model="invoiceType">
                <el-radio-button label="putong">增值税普通发票</el-radio-button>
                <el-radio-button label="zhuangyong"
                  >增值税专用发票</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="mainallcontent" v-if="!isInvoiceType">
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>开具类型：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue2">
                <el-radio-group v-model="issuingtype">
                  <el-radio-button label="company">企业</el-radio-button>
                  <el-radio-button label="people">个人</el-radio-button>
                </el-radio-group>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent" v-if="!isIssuingType">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>发票抬头：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="titlevalue3">
                  <el-input
                    ref="titlevalue3"
                    clearable
                    v-model="loginForm.titlevalue3"
                    class="namevalue"
                    size="small"
                    placeholder="例：北京无觅科技有限公司"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent" v-else>
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>发票抬头：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="titlevalue">
                  <el-input
                    ref="titlevalue"
                    clearable
                    v-model="loginForm.titlevalue"
                    class="namevalue"
                    size="small"
                    placeholder="例：张三"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent" v-if="!isIssuingType">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>纳税人识别号：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="identityidvalue">
                  <el-input
                    ref="identityidvalue"
                    clearable
                    v-model="loginForm.identityidvalue"
                    class="namevalue"
                    size="small"
                    placeholder="请输入15位或18位的英文字符、数字"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>邮箱：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="email">
                  <el-input
                    ref="email"
                    clearable
                    v-model="loginForm.email"
                    class="namevalue"
                    size="small"
                    placeholder="请输入邮箱地址，用于接收电子发票"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="mainallcontent" v-else>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>发票抬头：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="titlevalue2">
                  <el-input
                    ref="titlevalue2"
                    clearable
                    v-model="loginForm.titlevalue2"
                    class="namevalue"
                    size="small"
                    placeholder="例：北京无觅科技有限公司"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>纳税人识别号：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="identityidvalue2">
                  <el-input
                    ref="identityidvalue2"
                    clearable
                    v-model="loginForm.identityidvalue2"
                    class="namevalue"
                    size="small"
                    placeholder="请输入15位或18位的英文字符、数字"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>基本户开户银行名：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="banknamevalue">
                  <el-input
                    ref="banknamevalue"
                    clearable
                    v-model="loginForm.banknamevalue"
                    class="namevalue"
                    size="small"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>基本户开户账号：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="bankidvalue">
                  <el-input
                    ref="bankidvalue"
                    clearable
                    v-model="loginForm.bankidvalue"
                    class="namevalue"
                    size="small"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>公司注册地址：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="registerplacevalue">
                  <el-input
                    ref="registerplacevalue"
                    clearable
                    v-model="loginForm.registerplacevalue"
                    class="namevalue"
                    size="small"
                    placeholder="您公司营业执照上的注册地址"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>公司联系电话：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="registerphonevalue">
                  <el-input
                    ref="registerphonevalue"
                    clearable
                    v-model="loginForm.registerphonevalue"
                    class="namevalue"
                    size="small"
                    placeholder="请输入电话号码"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
            <div class="labelname2"><span>发票接收信息</span></div>
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>邮箱：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="email">
                  <el-input
                    ref="email"
                    clearable
                    v-model="loginForm.email"
                    class="namevalue"
                    size="small"
                    placeholder="请输入邮箱地址，用于接收电子发票"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- <div class="maincontent">
          <div class="labelname2"><span>发票邮寄信息</span></div>
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>收件人姓名：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="recipientnamevalue">
                  <el-input
                    ref="recipientnamevalue"
                    clearable
                    v-model="loginForm.recipientnamevalue"
                    class="namevalue"
                    size="small"
                    placeholder="请输入收件人姓名"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>收件人电话：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="recipientphonevalue">
                  <el-input
                    ref="recipientphonevalue"
                    clearable
                    v-model="loginForm.recipientphonevalue"
                    class="namevalue"
                    size="small"
                    placeholder="请输入收件人电话号码"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>收件人地址：</span></div>
            </el-col>
            <el-col :span="18">
              <div class="namevalue">
                <el-form-item prop="recipientaddressvalue">
                  <el-input
                    ref="recipientaddressvalue"
                    clearable
                    v-model="loginForm.recipientaddressvalue"
                    class="namevalue"
                    size="small"
                    placeholder="请输入收件人地址"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div> -->
      </div>
    </el-form>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showcreate: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const validateTiTle = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入发票抬头"));
      } else {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入邮箱地址，用于接收电子发票"));
      } else {
        callback();
      }
    };
    const validateIdentityid = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入纳税人识别号"));
      } else {
        callback();
      }
    };
    const validateTiTle2 = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入发票抬头"));
      } else {
        callback();
      }
    };
    const validateTiTle3 = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入发票抬头"));
      } else {
        callback();
      }
    };
    const validateBankName = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入基本户开户银行名"));
      } else {
        callback();
      }
    };
    const validateIdentityid2 = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入纳税人识别号"));
      } else {
        callback();
      }
    };
    const validateBankId = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入基本户开户账号"));
      } else {
        callback();
      }
    };
    const validateRegisterPlace = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入公司注册地址"));
      } else {
        callback();
      }
    };
    const validaterRgisterPhone = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入公司联系电话"));
      } else {
        callback();
      }
    };
    const validateRecipientName = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入收件人姓名"));
      } else {
        callback();
      }
    };
    const validateRecipientPhone = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入收件人电话"));
      } else {
        callback();
      }
    };
    const validateRecipientAddress = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输收件人地址"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        titlevalue: "",
        titlevalue2: "",
        titlevalue3: "",
        identityidvalue: "",
        email: "",
        identityidvalue2: "",
        banknamevalue: "",
        bankidvalue: "",
        registerplacevalue: "",
        registerphonevalue: "",
        recipientnamevalue: "",
        recipientphonevalue: "",
        recipientaddressvalue: "",
      },
      loginRules: {
        titlevalue: [
          { required: true, trigger: "blur", validator: validateTiTle },
        ],
        email: [{ required: true, trigger: "blur", validator: validateEmail }],
        identityidvalue: [
          { required: true, trigger: "blur", validator: validateIdentityid },
        ],
        titlevalue2: [
          { required: true, trigger: "blur", validator: validateTiTle2 },
        ],
        titlevalue3: [
          { required: true, trigger: "blur", validator: validateTiTle3 },
        ],
        identityidvalue2: [
          { required: true, trigger: "blur", validator: validateIdentityid2 },
        ],
        banknamevalue: [
          { required: true, trigger: "blur", validator: validateBankName },
        ],
        bankidvalue: [
          { required: true, trigger: "blur", validator: validateBankId },
        ],
        registerplacevalue: [
          { required: true, trigger: "blur", validator: validateRegisterPlace },
        ],
        registerphonevalue: [
          { required: true, trigger: "blur", validator: validaterRgisterPhone },
        ],
        recipientnamevalue: [
          { required: true, trigger: "blur", validator: validateRecipientName },
        ],
        recipientphonevalue: [
          {
            required: true,
            trigger: "blur",
            validator: validateRecipientPhone,
          },
        ],
        recipientaddressvalue: [
          {
            required: true,
            trigger: "blur",
            validator: validateRecipientAddress,
          },
        ],
      },

      invoiceType: "putong",
      issuingtype: "company",
      isInvoiceType: false,
      isIssuingType: false,
    };
  },
  watch: {
    invoiceType: function (val) {
      if (val == "putong") {
        this.isInvoiceType = false;
      } else {
        this.isInvoiceType = true;
      }
    },
    issuingtype: function (val) {
      if (val == "company") {
        this.isIssuingType = false;
      } else {
        this.isIssuingType = true;
      }
    },
  },
  components: {
    Models,
  },
  methods: {
    closecreate() {
      this.namevalue = "";
      this.$emit("closecreate");
    },
    confirmcreate() {
      if (!this.isInvoiceType) {
        //普通
        if (!this.isIssuingType) {
          //企业
          this.$refs.loginForm.validate((valid) => {
            console.log("referer: " + document.referrer);
            if (valid) {
              const data = {
                Type: "增值税普通发票",
                IssueType: "企业",
                Medium: "电子发票",
                Title: this.loginForm.titlevalue3,
                Email: this.loginForm.email,
                IdentityId: this.loginForm.identityidvalue,
              };
              this.$store
                .dispatch("user/createinvoiceinfo", { ...data })
                .then((res) => {
                  if (res.RetCode == 0) {
                    this.$message.success("创建成功");
                    this.closecreate();
                  }
                });
            }
          });
        } else {
          //个人
          this.$refs.loginForm.validate((valid) => {
            console.log("referer: " + document.referrer);
            if (valid) {
              const data = {
                Type: "增值税普通发票",
                IssueType: "个人",
                Medium: "电子发票",
                Title: this.loginForm.titlevalue,
                Email: this.loginForm.email,
              };
              this.$store
                .dispatch("user/createinvoiceinfo", { ...data })
                .then((res) => {
                  if (res.RetCode == 0) {
                    this.$message.success("创建成功");
                    this.closecreate();
                  }
                });
            }
          });
        }
      } else {
        //专用

        this.$refs.loginForm.validate((valid) => {
          console.log("referer: " + document.referrer);
          if (valid) {
            const data = {
              Type: "增值税专用发票",
              IssueType: "企业",
              Medium: "电子发票",
              Title: this.loginForm.titlevalue2,
              IdentityId: this.loginForm.identityidvalue2,
              BankName: this.loginForm.banknamevalue,
              BankId: this.loginForm.bankidvalue,
              RegisterPlace: this.loginForm.registerplacevalue,
              RegisterPhone: this.loginForm.registerphonevalue,
              Email: this.loginForm.email,
            };
            this.$store
              .dispatch("user/createinvoiceinfo", { ...data })
              .then((res) => {
                if (res.RetCode == 0) {
                  this.$message.success("创建成功");
                  this.closecreate();
                }
              });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ifcreate {
  ::v-deep .el-dialog {
    width: 550px;
    height: 700px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 520px;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .labelname2 {
    min-height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .maincontent {
    margin-bottom: 0px;
  }
  .namevalue2 {
    margin-bottom: 20px;
  }
}
</style>