<template>
 <div class="recharge-wrap">
    <div class="bread-wrap">
      <span class="bread-title">发票管理/发票信息</span>
    </div>
  <div class="grouplist-wrap">
    <div class="content">
      <div class="list-top">
        <el-button type="primary" @click="ifshowcreate" class="creategroup"
          >新增</el-button
        >
      </div>
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="invoicedetaillist"
        :pagination="pagination"
        tableheight="calc(100vh - 300px)"
        :data="tabledata"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          fixed="right"
          label="操作"
          min-width="250"
        >
          <template slot-scope="scope">
            <el-button
              @click="showinvoiceinfo(scope.row)"
              class="resetbtn"
              size="mini"
              type="primary"
              >详情</el-button
            >
            <el-button
              @click="deleteinvoiceinfo(scope.row)"
              class="deletebtn"
              size="mini"
              type="primary"
              >删除</el-button
            >
            <el-button
              v-if = "!scope.row.Default"
              @click="setdefaultinvoiceinfo(scope.row)"
              class="resetbtn"
              size="mini"
              type="primary"
              >设为默认</el-button
            >
            <span
              v-else
              class="resetbtn"
              size="mini">
              &nbsp;&nbsp;&nbsp;默认</span>
            
          </template>
        </el-table-column>
      </com-table>
    </div>
   <add-invoice-detail
      title="新增发票信息"
      name="hahah"
      canceltext="取消"
      confirmtext="确定"
      :showcreate="showcreate"
      @closecreate="closecreate"
      :row="row"
    />
    <show-invoice-detail
      title="发票信息"
      name="hahah"
      :needfooter="false"
      :showdetail="showdetail"
      @closeshow="closeshow"
      :row="row"
    />
  </div>
  </div>
</template>
<script>
import AddInvoiceDetail from "./Models/AddInvoiceDetail";
import ShowInvoiceDetail from "./Models/ShowInvoiceDetail";
import ComTable from "@/components/Table";
import { invoicedetaillist } from "@/utils/tableList";
export default {
  name: "invoicedetaillist",
  data() {
    return {
      invoicedetaillist,
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      showcreate: false,
      showdelete: false,
      showdetail: false,
      row: {},
      
    };
  },
  components: {
    ComTable,
    AddInvoiceDetail,
    ShowInvoiceDetail,
  },
  mounted() {
    this.getinvoicelist();
  },
  methods: {
    getinvoicelist() {
      this.tabledata = [];
      this.$store.dispatch("user/listinvoiceinfo").then((res) => {
        // console.log("invoice list: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          for (let item of res.InvoiceInfoList) {
            if (item.Email.length != 0) {
              this.tabledata.push(item);
            }
          }
          // this.tabledata = res.InvoiceInfoList;
        }
      });
    },
    deleteinvoiceinfo(row) {
      let payload = {
        Id: row.Id,
      };
      this.$store
        .dispatch("user/deleteinvoiceinfo", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("删除成功");
            this.getinvoicelist();
          }
        });
    },
    setdefaultinvoiceinfo(row) {
      let payload = {
        Id: row.Id,
      };
      this.$store
        .dispatch("user/setdefaultinvoiceinfo", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("设置成功");
            this.getinvoicelist();
          }
        });
    },
    showinvoiceinfo(row) {
      this.row = row;
      this.showdetail = !this.showdetail;
    },
    ifshowcreate() {
      this.showcreate = !this.showcreate;
    },
    closecreate() {
      this.getinvoicelist();
      this.showcreate = !this.showcreate;
    },
    closedelete() {
      this.getinvoicelist();
      this.showdelete = !this.showdelete;
    },
    closeshow() {
      this.showdetail = !this.showdetail;
    },

  
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>