<template>
  <models
    :showmodel="showdetail"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    :needfooter="needfooter"
    @confirm="confirmshow"
    @closemodel="closeshow"
    class="ifcreate"
  >
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
      <div class="maincontent">
        <el-row>
          <el-col :span="6">
            <div class="labelname"><span>发票类型：</span></div>
          </el-col>
          <el-col :span="6">
            <div class="namevalue">
              <span>{{ row.Type }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="mainallcontent" v-if="row.Type == '增值税普通发票'">
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>开具类型：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.IssueType }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :span="6">
            <div class="labelname"><span>发票抬头：</span></div>
          </el-col>
          <el-col :span="6">
            <div class="namevalue">
              <span>{{ row.Title }}</span>
            </div>
          </el-col>
        </el-row>
        <div class="maincontent" v-if="row.IssueType != '个人'">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>纳税人识别号：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.IdentityId }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>邮箱：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.Email }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="mainallcontent" v-else>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>发票抬头：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.Title }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>纳税人识别号：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.IdentityId }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>基本户开户银行名：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.BankName }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>基本户开户账号：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.BankId }}</span>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>公司注册地址：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.RegisterPlace }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>公司联系电话：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.RegisterPhone }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>邮箱：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.Email }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
        <!-- <div class="maincontent">
          <div class="labelname2"><span>发票邮寄信息</span></div>
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>收件人姓名：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.RecipientName }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>收件人电话：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.RecipientPhone }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="maincontent">
          <el-row>
            <el-col :span="6">
              <div class="labelname"><span>收件人地址：</span></div>
            </el-col>
            <el-col :span="6">
              <div class="namevalue">
                <span>{{ row.RecipientAddress }}</span>
              </div>
            </el-col>
          </el-row>
        </div> -->
      </div>
    </el-form>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showdetail: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    needfooter: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Object,
    },
  },
  data() {
    const validateTiTle = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入发票抬头"));
      } else {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入可用的邮箱"));
      } else {
        callback();
      }
    };
    const validateIdentityid = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入纳税人识别号"));
      } else {
        callback();
      }
    };
    const validateTiTle2 = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入发票抬头"));
      } else {
        callback();
      }
    };
    const validateTiTle3 = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入发票抬头"));
      } else {
        callback();
      }
    };
    const validateBankName = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入基本户开户银行名"));
      } else {
        callback();
      }
    };
    const validateIdentityid2 = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入纳税人识别号"));
      } else {
        callback();
      }
    };
    const validateBankId = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入基本户开户账号"));
      } else {
        callback();
      }
    };
    const validateRegisterPlace = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入公司注册地址"));
      } else {
        callback();
      }
    };
    const validaterRgisterPhone = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入公司联系电话"));
      } else {
        callback();
      }
    };
    const validateRecipientName = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入收件人姓名"));
      } else {
        callback();
      }
    };
    const validateRecipientPhone = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入收件人电话"));
      } else {
        callback();
      }
    };
    const validateRecipientAddress = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输收件人地址"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        titlevalue: "",
        titlevalue2: "",
        titlevalue3: "",
        identityidvalue: "",
        email: "",
        identityidvalue2: "",
        banknamevalue: "",
        bankidvalue: "",
        registerplacevalue: "",
        registerphonevalue: "",
        recipientnamevalue: "",
        recipientphonevalue: "",
        recipientaddressvalue: "",
      },
      loginRules: {
        titlevalue: [
          { required: true, trigger: "blur", validator: validateTiTle },
        ],
        email: [{ required: true, trigger: "blur", validator: validateEmail }],
        identityidvalue: [
          { required: true, trigger: "blur", validator: validateIdentityid },
        ],
        titlevalue2: [
          { required: true, trigger: "blur", validator: validateTiTle2 },
        ],
        titlevalue3: [
          { required: true, trigger: "blur", validator: validateTiTle3 },
        ],
        identityidvalue2: [
          { required: true, trigger: "blur", validator: validateIdentityid2 },
        ],
        banknamevalue: [
          { required: true, trigger: "blur", validator: validateBankName },
        ],
        bankidvalue: [
          { required: true, trigger: "blur", validator: validateBankId },
        ],
        registerplacevalue: [
          { required: true, trigger: "blur", validator: validateRegisterPlace },
        ],
        registerphonevalue: [
          { required: true, trigger: "blur", validator: validaterRgisterPhone },
        ],
        recipientnamevalue: [
          { required: true, trigger: "blur", validator: validateRecipientName },
        ],
        recipientphonevalue: [
          {
            required: true,
            trigger: "blur",
            validator: validateRecipientPhone,
          },
        ],
        recipientaddressvalue: [
          {
            required: true,
            trigger: "blur",
            validator: validateRecipientAddress,
          },
        ],
      },

      invoiceType: "putong",
      issuingtype: "company",
      isInvoiceType: false,
      isIssuingType: false,
    };
  },

  components: {
    Models,
  },
  methods: {
    closeshow() {
      this.$emit("closeshow");
    },
    confirmshow() {
      this.$emit("closeshow");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifcreate {
  ::v-deep .el-dialog {
    width: 550px;
    height: 500px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }

  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .namevalue {
    min-height: 32px;
    line-height: 32px;
    width: 300px;
    // text-align: center;
  }

  .labelname2 {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
  }
  .maincontent {
    margin-bottom: 0px;
  }
}
</style>